<div [formGroup]="parentFormGroup">
	<mat-form-field class="input-field" appearance="outline">
		<mat-label>{{ popisek | lokalizace }}</mat-label>
		<mat-select [formControlName]="nazevPrvkuFormulare" [required]="jeHodnotaPovinna()" [attr.disabled]="vypnoutPrvekFormulare()">
			<mat-option *ngIf="prvniHodnotaNull">{{ nezvolenyPopisek | lokalizace }}</mat-option>
			<ng-container *ngIf="!dataFromObject">
				<mat-option *ngFor="let item of polozky | klice | selectSort: seraditPolozky: polozky" [value]="item.key">{{ item.value | typEnumu: polozky }}</mat-option>
			</ng-container>
			<ng-container *ngIf="dataFromObject">
				<mat-option *ngFor="let item of polozky | selectSort: seraditPolozky: null : objectKeyName" [value]="item[objectValueName]">{{ item[objectKeyName] }}</mat-option>
			</ng-container>
		</mat-select>
		<mat-hint>{{ napoveda | lokalizace }}</mat-hint>
		<mat-error>{{ pripravitErrorZpravu() }}</mat-error>
	</mat-form-field>
</div>
