import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {KeycloakAngularModule, KeycloakService} from 'keycloak-angular';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {DomuModule} from './layout/domu/domu.module';
import {AutentizaceService} from './service/bezpecnost/autentizace.service';
import {initializer} from './service/bezpecnost/keycloak-init';
import {FormularMultipleAutocompleteService} from './service/formular-multiple-autocomplete.service';
import {RouteInterceptorService} from './service/route-interceptor.service';
import {ScrollService} from "./service/scroll.service";
import {UpozorneniService} from './service/upozorneni.service';
import {HttpPozadavekInterceptor} from './shared/http-pozadavek.interceptor';
import {LayoutModule} from './shared/layout/layout.module';

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserModule,
		HttpClientModule,
		CommonModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		LayoutModule,
		DomuModule,
		KeycloakAngularModule
	],
	providers: [
		KeycloakService,
		AutentizaceService,
		UpozorneniService,
		RouteInterceptorService,
		FormularMultipleAutocompleteService,
		ScrollService,
		{
			provide: APP_INITIALIZER,
			useFactory: initializer,
			multi: true,
			deps: [KeycloakService]
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpPozadavekInterceptor,
			multi: true
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}
