import moment from 'moment';
import {NastrojeObecne} from './nastroje-obecne';
import {NastrojePorovnani} from './nastroje-porovnani';

export class NastrojeDataACasu {

	public static readonly FORMAT_ROKU = 'YYYY';
	public static readonly FORMAT_DATA = 'DD. MM. YYYY';
	public static readonly FORMAT_DATA_A_CASU = 'DD. MM. YYYY HH:mm';
	public static readonly FORMAT_DATA_A_CASU_S_PRESNOSTI_NA_SEKUNDY = 'DD. MM. YYYY HH:mm:ss';
	public static readonly FORMAT_CASU_S_PRESNOSTI_NA_MINUTY = 'HH:mm';
	public static readonly VZOR_CASU = '^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$';

	public static prevestTextNaCas(hodnota: string): string {
		if(!NastrojeObecne.jePrazdnaHodnota(hodnota) && hodnota.length == 5 && hodnota.slice(0, 2) as any < 24 && hodnota.slice(2, 3) == ':' && hodnota.slice(3, 5) as any < 60) {
			return hodnota
		}
		return NastrojeObecne.jePrazdnaHodnota(hodnota) ? null : '00:00'
	}

	public static prevestTextNaDatum(hodnota: string, vzor: string): Date {
		return NastrojeObecne.jePrazdnaHodnota(hodnota) ? null : moment(hodnota, vzor).toDate();
	}

	public static formatovatDatum(datum: Date, vzor: string): string {
		return moment(datum).format(vzor).toString();
	}

	public static formatovatDatumSCasovouZonou(datum: Date, vzor: string): string {
		const timezoneOffsetInMinutes = datum.getTimezoneOffset();

		return moment(datum).format(vzor).toString() + ` ${this.pripravitOffsetCasoveZonyVMinutachJakoText(timezoneOffsetInMinutes)}`;
	}

	public static jeRozsahValidni(prvniDatum: Date, druheDatum: Date): boolean {
		return NastrojePorovnani.porovnatDatumy(prvniDatum, druheDatum) <= 0;
	}

	public static jeDatumVRozsahu(datumJakoText: string, datumOdJakoText: string, datumDoJakoText: string): boolean {
		const [datum, datumOd, datumDo] = [datumJakoText, datumOdJakoText, datumDoJakoText].map(date => this.prevestTextNaDatum(date, this.FORMAT_DATA_A_CASU_S_PRESNOSTI_NA_SEKUNDY));

		if (!datum) {
			return NastrojeObecne.jePrazdnaHodnota(datumOd) && NastrojeObecne.jePrazdnaHodnota(datumDo);
		}

		if (datumDo) {
			datumDo.setHours(23, 59, 59);
		}

		return (datumOd ? NastrojePorovnani.porovnatDatumy(datumOd, datum) <= 0 : true) && (datumDo ? NastrojePorovnani.porovnatDatumy(datum, datumDo) <= 0 : true);
	}

	public static pripravitRozdilMeziDaty(firstDate: Date | string, secondDate: Date | string, format?: string): number {
		return moment(firstDate, format).diff(moment(secondDate, format));
	}

	private static pripravitOffsetCasoveZonyVMinutachJakoText(offsetCasoveZonyVMinutach: number): string {
		const absolutniHodnota = Math.abs(offsetCasoveZonyVMinutach);
		let hodiny: any = Math.floor(absolutniHodnota / 60);
		let minuty: any = absolutniHodnota - hodiny * 60;

		if (hodiny < 10) {
			hodiny = '0' + hodiny;
		}

		if (minuty < 10) {
			minuty = '0' + minuty;
		}

		return `GMT${offsetCasoveZonyVMinutach > 0 ? '-' : '+'}${hodiny}:${minuty}`;
	}
}
