import {TypLokalizace} from '../../data/general/enum/typ-lokalizace.enum';
import {DruhFormulare} from '../../data/kontextove-napovedy/druh-formulare.enum';
import {DuvodZruseniSubjektu} from '../../data/subjekty/enum/duvod-zruseni-subjektu.enum';
import {TypAkce} from '../../data/systemove-zaznamy/enum/typ-akce.enum';
import {UrovenSystemovehoZaznamu} from '../../data/systemove-zaznamy/enum/uroven-systemoveho.zaznamu';
import {ZpusobProvedeniAkce} from '../../data/systemove-zaznamy/enum/zpusob-provedeni-akce.enum';
import {Colors} from '../../data/testing-page/enum/colors.enum';
import {FavouriteColor} from '../../data/testing-page/enum/favourite-color.enum';
import {DuvodZruseniUzivatele} from '../../data/uzivatele/enum/duvod-zruseni-uzivatele.enum';
import {NastrojeObecne} from './nastroje-obecne';
import {NastrojeRetezcu} from './nastroje-retezcu';
import {StavZadosti} from '../../data/zadosti/enum/stavZadosti.enum';
import {ZpusobDolozeniOpravneni} from '../../data/zadosti/enum/zpusob-dolozeni-opravneni.enum';
import {StavSubjektu} from '../../data/subjekty/enum/stav-subjektu.enum';
import {PravniForma} from '../../data/subjekty/enum/pravni-forma.enum';
import {Role} from '../../data/sdilene/enum/role.enum';
import {StavUzivatele} from '../../data/uzivatele/enum/stav-uzivatele.enum';
import {StavIenu} from '../../data/ieny/stav-ienu';
import {Priorita} from '../../data/ukoly/enum/priorita.enum';
import {StavUkolu} from '../../data/ukoly/enum/stav-ukolu.enum';
import {ZdrojovaSluzba} from '../../data/systemove-zaznamy/enum/zdrojova-sluzba.enum';

export class NastrojeEnumu {

	private static readonly enumy = {
		Colors,
		DruhFormulare,
		DuvodZruseniSubjektu,
		DuvodZruseniUzivatele,
		FavouriteColor,
		PravniForma,
		Priorita,
		Role,
		StavIenu,
		StavSubjektu,
		StavUzivatele,
		StavZadosti,
		TypAkce,
		TypLokalizace,
		StavUkolu,
		UrovenSystemovehoZaznamu,
		ZpusobDolozeniOpravneni,
		ZpusobProvedeniAkce,
		ZdrojovaSluzba
	};

	public static pripravitTriduEnumuJakoText(tridaEnumu: any): string {
		for (const item of Object.entries(this.enumy)) {
			if (tridaEnumu === item[1]) {
				return item[0];
			}
		}

		return '';
	}

	public static pripravitKlicHodnotyEnumuProLokalizaci(nazevTridyEnumu: string, hodnotaEnumu: string): string {
		if (NastrojeObecne.jePrazdnaHodnota(nazevTridyEnumu) || NastrojeObecne.jePrazdnaHodnota(hodnotaEnumu)) {
			return '';
		}

		const nazevTridyEnumuProLokalizaci = NastrojeRetezcu.prevestUpperCamelCaseNaLowerCamelCase(nazevTridyEnumu);
		const nazevHodnotyEnumuProLokalizaci = NastrojeRetezcu.prevestUpperSnakeCaseNaLowerCamelCase(hodnotaEnumu);

		return NastrojeObecne.formatovatText('{0}.{1}', nazevTridyEnumuProLokalizaci, nazevHodnotyEnumuProLokalizaci);
	}

	public static pripravitEnumDleNazvuTridy(nazevTridyEnumu: string): any {
		for (const item of Object.entries(this.enumy)) {
			if (nazevTridyEnumu === item[0]) {
				return item[1];
			}
		}

		return {};
	}
}
