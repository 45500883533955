import {Component, HostListener, OnInit} from '@angular/core';
import {AutentizaceService} from '../../../service/bezpecnost/autentizace.service';
import {prostredi} from '../../../../prostredi/prostredi';

@Component({
	selector: 'app-hlavicka',
	templateUrl: './hlavicka.component.html',
	styleUrls: ['./hlavicka.component.scss']
})
export class HlavickaComponent implements OnInit {

	@HostListener('window:resize', ['$event']) onResize() {
		this.skrytMobilniMenuDleSirkyObrazovky();
	}

	@HostListener('click', ['$event.target']) onClick(tlacitko) {
		this.deaktivovatMobilniMenu(tlacitko);
	}

	public readonly urlVerejneAplikace = prostredi.urlVerejneAplikace;

	private readonly maximalniSirkaMobilniObrazovky = 900;

	public jeUzivatelPrihlasen: boolean;
	public jmenoAPrijmeni: string;
	public jeMobilniMenuAktivni: boolean = false;

	constructor(
		private autentizaceService: AutentizaceService
	) {
	}

	ngOnInit(): void {
		this.pripravitInformaceOUzivateli();
	}

	public prihlasit(): void {
		this.autentizaceService.prihlasit();
	}

	public odhlasit(): void {
		this.autentizaceService.odhlasit();
	}

	public aktivovatMobilniMenu(): void {
		this.jeMobilniMenuAktivni = !this.jeMobilniMenuAktivni;
	}

	public deaktivovatMobilniMenu(tlacitko): void {
		if (tlacitko.classList.contains('gov-link')) {
			this.jeMobilniMenuAktivni = false;
		}
	}

	private pripravitInformaceOUzivateli(): void {
		this.jeUzivatelPrihlasen = this.autentizaceService.jeUzivatelPrihlasen();
		this.jmenoAPrijmeni = this.autentizaceService.pripravitJmenoAPrijmeni();
	}

	private skrytMobilniMenuDleSirkyObrazovky(): void {
		if (this.jeMobilniMenuAktivni && window.innerWidth >= this.maximalniSirkaMobilniObrazovky) {
			this.jeMobilniMenuAktivni = false;
		}
	}
}
