<footer class="gov-container gov-container--wide u-bg-color--grey-dark gov-footer">
	<div class="gov-container__content">
		<hr>
		<section class="gov-footer__lower">
			<p class="gov-footnote">{{ aktualniRok | datum: formatRoku }} {{ 'paticka.spolecnost' | lokalizace }}</p>
			<p class="gov-footnote">{{ 'paticka.verze' | lokalizace }} {{ verzeCislo }}</p>
		</section>

		<button class="gov-button gov-button--primary-outlined gov-button--inversed gov-footer__scroll-up gov-js--scroll-up"
				aria-labelledby="fo-scrollBtn"
				title="{{ 'paticka.tlacitko.zpetNahoru' | lokalizace }}"
				(click)="odskrolovatNaZacatekStranky()">
			<span id="fo-scrollBtn" class="u-sr-only gov-button__label">{{ 'paticka.tlacitko.zpetNahoru' | lokalizace }}</span>
		</button>
	</div>
</footer>
