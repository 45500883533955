export const prostredi = {
	produkcni: true,

	verze: 'Vyvojova',
	verzeCislo: '2.1.2',
	korenovaUrl: '/',

	url: 'https://dev.admin.novyvvz.com',
	urlVerejneAplikace: 'https://dev.novyvvz.com',

	keycloakUrl: 'https://dev.novyvvz.com/auth',
	realm: 'Administrace',
	clientId: 'vvz-frontend',

	hlavickyBackend: {
		'accept': 'application/json',
		'Content-Type': 'application/json',
	},
	hlavickyBackendStahovani: {
		'accept': '*/*',
	},

	//Swagger
	urlSwagger: 'https://virtserver.swaggerhub.com/ondrej.hladik/test/1.0.0',

	// Mikrosluzby
	urlAntivirovaKontrolaSouboru: 'https://dev.api.novyvvz.com/vvz-antivirova-kontrola',
	urlIen: 'https://dev.api.novyvvz.com/vvz-ien',
	urlKontextoveNapovedy: 'https://dev.api.novyvvz.com/vvz-kontextova-napoveda/napoveda',
	urlSpravaSubjektuAUzivatelu: 'https://dev.api.novyvvz.com/vvz-sprava-subjektu-a-uzivatelu',
	urlSystemoveZaznamy: 'https://dev.api.novyvvz.com/vvz-log',
	urlUkoly: 'https://dev.api.novyvvz.com/vvz-ukoly-administratora',
};
