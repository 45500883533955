import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthGuardService} from './service/bezpecnost/auth-guard.service';

const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./layout/domu/domu.module').then(m => m.DomuModule),
		canActivate: [AuthGuardService],
		data: {
			kontrolovatOpravneni: false
		}
	},
	{
		path: 'zadosti',
		loadChildren: () => import('./layout/zadosti/zadosti.module').then(m => m.ZadostiModule),
		canActivate: [AuthGuardService],
		data: {
			kontrolovatOpravneni: true
		}
	},
	{
		path: 'systemove-zaznamy',
		loadChildren: () => import('./layout/systemove-zaznamy/systemove-zaznamy.module').then(m => m.SystemoveZaznamyModule),
		canActivate: [AuthGuardService],
		data: {
			kontrolovatOpravneni: true
		}
	},
	{
		path: 'kontextove-napovedy',
		loadChildren: () => import('./layout/kontextove-napovedy/kontextove-napovedy.module').then(m => m.KontextoveNapovedyModule),
		data: {
			kontrolovatOpravneni: true
		}
	},
	{
		path: 'ukoly',
		loadChildren: () => import('./layout/ukoly/ukoly.module').then(m => m.UkolyModule),
		canActivate: [AuthGuardService],
		data: {
			kontrolovatOpravneni: true
		}
	},
	{
		path: 'registry',
		loadChildren: () => import('./layout/registry/registry.module').then(m => m.RegistryModule),
		canActivate: [AuthGuardService],
		data: {
			kontrolovatOpravneni: true
		}
	},
	{
		path: 'testovaci-stranka',
		loadChildren: () => import('./layout/testovaci-stranka/testovaci-stranka.module').then(m => m.TestovaciStrankaModule),
		canActivate: [AuthGuardService],
		data: {
			kontrolovatOpravneni: true
		}
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {
		preloadingStrategy: PreloadAllModules,
		scrollPositionRestoration: 'top'
	})],
	exports: [RouterModule],
	providers: [AuthGuardService]
})
export class AppRoutingModule {
}
