import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {LayoutModule} from '../../shared/layout/layout.module';
import {PipesModule} from '../../shared/pipes/pipes.module';
import {DomuRoutingModule} from './domu-routing.module';
import {DomuComponent} from './domu.component';

@NgModule({
	declarations: [
		DomuComponent
	],
	imports: [
		DomuRoutingModule,
		CommonModule,
		PipesModule,
		LayoutModule
	]
})
export class DomuModule {
}
