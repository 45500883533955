import {Component} from '@angular/core';
import {prostredi} from '../../../prostredi/prostredi';

@Component({
	selector: 'app-home',
	templateUrl: './domu.component.html',
	styleUrls: ['./domu.component.scss']
})
export class DomuComponent {

	public readonly urlAplikace = prostredi.url;
}
