export enum DruhFormulare {
	CZ01 = 'CZ01',
	CZ02 = 'CZ02',
	CZ03 = 'CZ03',
	CZ04 = 'CZ04',
	CZ05 = 'CZ05',
	CZ06 = 'CZ06',
	CZ07 = 'CZ07',
	F01 = 'F01',
	F02 = 'F02',
	F03 = 'F03',
	F04 = 'F04',
	F05 = 'F05',
	F06 = 'F06',
	F07 = 'F07',
	F08 = 'F08',
	F12 = 'F12',
	F13 = 'F13',
	F14 = 'F14',
	F15 = 'F15',
	F16 = 'F16',
	F17 = 'F17',
	F18 = 'F18',
	F19 = 'F19',
	F20 = 'F20',
	F21 = 'F21',
	F22 = 'F22',
	F23 = 'F23',
	F24 = 'F24',
	F25 = 'F25',
	ZADOST = 'ZADOST',
	REGISTRACE = 'REGISTRACE',
	NOTIFIKACE = 'NOTIFIKACE'
}
