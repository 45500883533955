import {AbstractControl, UntypedFormGroup, ValidatorFn} from '@angular/forms';

export class NastrojeFormularu {

	public static resetovatDataFormulare(formGroup: UntypedFormGroup): void {
		formGroup.reset();
		formGroup.markAsPristine();
		formGroup.markAsUntouched();
		this.oznacitJakoNeodeslane(formGroup);
	}

	public static oznacitJakoOdeslane(formGroup: UntypedFormGroup): void {
		this.nastavitOdeslane(formGroup, true);
	}

	public static oznacitJakoNeodeslane(formGroup: UntypedFormGroup): void {
		this.nastavitOdeslane(formGroup, false);
	}

	public static oznacitPrvekJakoDotceny(...controls: AbstractControl[]): void {
		controls.forEach(control => control.markAsTouched());
	}

	public static aktualizovatPrvkySVyslanimUdalosti(...controls: AbstractControl[]): void {
		controls.forEach(control => control.updateValueAndValidity({emitEvent: true}));
	}

	public static nastavitHodnotuPouzeSobeBezVyslaniUdalosti(formControl: AbstractControl, value: any): void {
		formControl.setValue(value, {onlySelf: true, emitEvent: false});
	}

	public static aktualizovatPrvekPouzeSobeBezVyslaniUdalosti(...controls: AbstractControl[]): void {
		controls.forEach(control => control.updateValueAndValidity({onlySelf: true, emitEvent: false}));
	}

	public static povolitPrvky(...controls: AbstractControl[]): void {
		controls.forEach(control => control.enable());
	}

	public static vypnoutPrvkyAVymazatObsah(...controls: AbstractControl[]): void {
		controls.forEach(control => {
			control.disable();
			control.reset('');
		});
	}

	public static vypnoutPrvky(...controls: AbstractControl[]): void {
		controls.forEach(control => {
			control.disable();
		});
	}

	public static vypnoutPrvkyPouzeSobeBezVyslaniUdalosti(...controls: AbstractControl[]): void {
		controls.forEach(control => control.disable({onlySelf: true, emitEvent: false}));
	}

	public static odstranitPrvky(formGroup: UntypedFormGroup, ...nazvyPrvku: string[]): void {
		nazvyPrvku.forEach(nazev => formGroup.removeControl(nazev));
	}

	public static pridatValidatory(control: AbstractControl, validatorFunctions: ValidatorFn[]): void {
		if (control.validator != null) {
			validatorFunctions.unshift(control.validator);
		}

		control.setValidators(validatorFunctions);
	}

	public static jePrvekSoucastiTohotoFormulare(element: Element, id: string): boolean {
		return $(element).closest(`[id=${id}]`).length > 0;
	}

	public static jePrvekSoucastiFiltruDatoveTabulky(element: Element): boolean {
		return $(element).closest('app-frontend-processed-data-table-filter').length > 0 || $(element).closest('app-backend-processed-data-table-filter').length > 0;
	}

	public static jeElementTextovyVstup(element: Element): boolean {
		return $(element).is('input');
	}

	public static jePrvekVypnuty(control: AbstractControl): boolean {
		return control.disabled;
	}

	private static nastavitOdeslane(formGroup: UntypedFormGroup, sent: boolean): void {
		(formGroup as any).sent = sent;
	}
}
