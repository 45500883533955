import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {AbstraktniFormularovyPrvekComponent} from '../abstraktni-formularovy-prvek.component';
import {NastrojeCisel} from '../../../../nastroje/nastroje-cisel';
import {NastrojeObecne} from '../../../../nastroje/nastroje-obecne';
import {Lokalizace} from '../../../../lokalizace';

@Component({
	selector: 'app-formular-input',
	templateUrl: './formular-input.component.html',
	styleUrls: ['./formular-input.component.scss']
})
export class FormularInputComponent extends AbstraktniFormularovyPrvekComponent implements OnInit, OnDestroy {

	/**
	 * Defines input type
	 */
	@Input() typ = 'text';

	/**
	 * Defines whether the input value will be formatted as number or not
	 */
	@Input() formatovatJakoCislo = false;

	/**
	 * Defines whether the input value will be formatted as telephone number or not
	 */
	@Input() formatovatJakoTelefonniCisloSPredvolbou = false;

	/**
	 * Defines telephone area code
	 */
	@Input() formatovatJakoTelefonniCisloPredvolba = '+420';

	/**
	 * Defines whether number formatter should apply separator or not
	 */
	@Input() cisloSOddelovacem = true;

	/**
	 * Defines rounding of formatted number
	 */
	@Input() pocetCiselZaokrouhleni = 2;

	private posluchaciFormularovehoPrvku: Subscription[] = [];

	constructor() {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.formatovatVstupniHodnotu();

		if (this.formatovatJakoTelefonniCisloSPredvolbou) {
			this.registrovatPosluchaceZmenyTelefonnihoCisla();
		}
	}

	ngOnDestroy(): void {
		this.posluchaciFormularovehoPrvku.forEach(posluchac => posluchac.unsubscribe());
	}

	public formatovatVstupniHodnotu(): void {
		if (this.formatovatJakoCislo) {
			this.formController.setValue(NastrojeCisel.formatovatCislo(NastrojeCisel.prevestNaCislo(this.formController.value), this.cisloSOddelovacem, this.pocetCiselZaokrouhleni));
		}
	}

	private registrovatPosluchaceZmenyTelefonnihoCisla(): void {
		this.posluchaciFormularovehoPrvku.push(this.formController.valueChanges.subscribe(hodnota => {
			if (!NastrojeObecne.jePrazdnaHodnota(hodnota) && hodnota.length === 1 && hodnota[0] !== '+' && !isNaN(hodnota)) {
				this.formController.setValue(Lokalizace.formatovatText('{0} {1}', this.formatovatJakoTelefonniCisloPredvolba, hodnota));
			}
		}));
	}
}
