export class NastrojeOdrolovani {

	public static odskrolovatNaZacatekStranky(): void {
		window.scroll({
			top: 0,
			left: 0,
			behavior: 'smooth'
		});
	}

	public static odrolovatPlynule(idElementu: string): void {
		document.getElementById(idElementu).scrollIntoView({behavior: 'smooth'});
	}
}
