import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import {NgxMatDateAdapter} from '@angular-material-components/datetime-picker';
import {AbstraktniFormularovyPrvekComponent} from '../abstraktni-formularovy-prvek.component';
import {TypLokalizace} from '../../../../../data/general/enum/typ-lokalizace.enum';
import {NgxCustomDateAdapter} from './ngx-custom-date-adapter';

@Component({
	selector: 'app-formular-kalendar-a-cas',
	templateUrl: './formular-kalendar-a-cas.component.html',
	styleUrls: ['./formular-kalendar-a-cas.component.scss'],
	providers: [
		{
			provide: MAT_DATE_LOCALE,
			useValue: TypLokalizace.CZ.toLowerCase()
		},
		{
			provide: NgxMatDateAdapter,
			useClass: NgxCustomDateAdapter
		}
	],
	encapsulation: ViewEncapsulation.None
})
export class FormularKalendarACasComponent extends AbstraktniFormularovyPrvekComponent implements OnInit {

	constructor() {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();
	}
}
