import {Component, OnInit} from '@angular/core';
import {AbstraktniFormularovyPrvekComponent} from '../abstraktni-formularovy-prvek.component';

@Component({
	selector: 'app-formular-checkbox',
	templateUrl: './formular-checkbox.component.html',
	styleUrls: ['./formular-checkbox.component.scss']
})
export class FormularCheckboxComponent extends AbstraktniFormularovyPrvekComponent implements OnInit {

	constructor() {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();
	}
}
