import {Injectable} from '@angular/core';
import {NgxMatNativeDateAdapter} from '@angular-material-components/datetime-picker';
import {NastrojeObecne} from '../../../../nastroje/nastroje-obecne';
import {NastrojeDataACasu} from '../../../../nastroje/nastroje-data-a-casu';

@Injectable()
export class NgxCustomDateAdapter extends NgxMatNativeDateAdapter {

	parse(value: any): Date | null {
		return NastrojeObecne.jePrazdnaHodnota(value) ? null : NastrojeDataACasu.prevestTextNaDatum(value, NastrojeDataACasu.FORMAT_DATA_A_CASU_S_PRESNOSTI_NA_SEKUNDY);
	}

	getFirstDayOfWeek(): number {
		return 1;
	}
}
