<div [formGroup]="parentFormGroup">
	<mat-form-field class="input-field" appearance="outline">
		<mat-label>{{ popisek | lokalizace }}</mat-label>
		<input matInput
			   [formControlName]="nazevPrvkuFormulare"
			   [ngxMatDatetimePicker]="dateAndTimePicker"
			   [required]="jeHodnotaPovinna()"
			   [attr.disabled]="vypnoutPrvekFormulare()">
		<mat-datepicker-toggle matSuffix [for]="$any(dateAndTimePicker)"></mat-datepicker-toggle>
		<ngx-mat-datetime-picker #dateAndTimePicker class="ngx-mat-datetime-picker" [showSeconds]="true"></ngx-mat-datetime-picker>
		<mat-hint>{{ napoveda | lokalizace }}</mat-hint>
		<mat-error>{{ pripravitErrorZpravu() }}</mat-error>
	</mat-form-field>
</div>
