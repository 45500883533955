import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {DateAdapter, MAT_DATE_LOCALE} from '@angular/material/core';
import {AbstraktniFormularovyPrvekComponent} from '../abstraktni-formularovy-prvek.component';
import {CustomDateAdapter} from './custom-date-adapter';
import {TypLokalizace} from '../../../../../data/general/enum/typ-lokalizace.enum';

@Component({
	selector: 'app-formular-kalendar',
	templateUrl: './formular-kalendar.component.html',
	styleUrls: ['./formular-kalendar.component.scss'],
	providers: [
		{
			provide: MAT_DATE_LOCALE,
			useValue: TypLokalizace.CZ.toLowerCase()
		}, {
			provide: DateAdapter,
			useClass: CustomDateAdapter
		}
	],
	encapsulation: ViewEncapsulation.None
})
export class FormularKalendarComponent extends AbstraktniFormularovyPrvekComponent implements OnInit {

	constructor() {
		super();
	}

	public ngOnInit(): void {
		super.ngOnInit();
	}
}
