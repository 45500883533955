import {Component} from '@angular/core';
import {prostredi} from '../../../../prostredi/prostredi';
import {NastrojeDataACasu} from '../../nastroje/nastroje-data-a-casu';
import {NastrojeOdrolovani} from '../../nastroje/nastroje-odrolovani';

@Component({
	selector: 'app-paticka',
	templateUrl: './paticka.component.html',
	styleUrls: ['./paticka.component.scss']
})
export class PatickaComponent {

	public readonly aktualniRok = new Date();
	public readonly formatRoku = NastrojeDataACasu.FORMAT_ROKU;
	public readonly verzeCislo = prostredi.verzeCislo;

	public odskrolovatNaZacatekStranky(): void {
		NastrojeOdrolovani.odskrolovatNaZacatekStranky();
	}

}
