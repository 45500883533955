import czJson from '../../assets/lokalizace/cz.json';
import {NastrojeObecne} from './nastroje/nastroje-obecne';

export class Lokalizace {

	private static readonly data = czJson;

	public static pripravitText(klic: string): string {
		return this.data && this.data[klic] ? this.data[klic] : klic;
	}

	public static formatovatText(klic: string, ...parametry: any[]): string {
		return NastrojeObecne.formatovatText(Lokalizace.pripravitText(klic), ...parametry);
	}

	public static prevestKlicNaLowerCamelCase(klic: string) {
		return klic.replace(/(^\w|[.]\w)/g, m => m.toLowerCase());
	}
}
