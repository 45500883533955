<header class="gov-container gov-container--no-y-offset gov-header">
	<div class="gov-container__content">
		<div class="gov-header__holder">
			<div class="gov-header__left" style="display: flex;">
				<a href="https://mmr.cz/cs/uvod" target="_blank" rel="noreferrer noopener" class="gov-footer__image-link">
					<img alt="MMR logo" src="/assets/obrazky/loga/mmr_cr_negativni.a57d54b14d68578b055170de4eb73483.svg" width="130">
				</a>
				<a class="gov-logo gov-logo--inversed" href="{{ urlVerejneAplikace }}">
					VĚSTNÍK VEŘEJNÝCH ZAKÁZEK
				</a>
			</div>

			<div class="gov-header__right">
				<div *ngIf="!jeUzivatelPrihlasen" class="gov-header__item gov-header__item--login">
					<button class="gov-button gov-button--primary-outlined gov-button--inversed"
							(click)="prihlasit()">{{ 'hlavicka.prihlasitSe' | lokalizace }}</button>
				</div>

				<div *ngIf="jeUzivatelPrihlasen" class="gov-header__item gov-header__item--login">
					<a class="jmeno-prihlaseneho gov-button gov-button--inversed">{{ jmenoAPrijmeni }}</a>
					<button class="odhlasit gov-button gov-button--small gov-button--inversed"
							title="{{ 'hlavicka.odhlasitSe' | lokalizace }}"
							(click)="odhlasit()"><span class="gov-icon gov-icon--exit"></span>
					</button>
				</div>

				<div class="gov-header__fixed-items">
					<div class="gov-header__item gov-header__item--mobile">
						<button class="gov-header__hamburger"
								[class.active]="jeMobilniMenuAktivni"
								(click)="aktivovatMobilniMenu()">
							<span></span>
							<span></span>
							<div>{{ 'hlavicka.menu' | lokalizace }}</div>
						</button>
					</div>
				</div>
			</div>
		</div>

		<hr class="gov-header__separator">
		<div class="gov-header__overlay"
			 [class.active]="jeMobilniMenuAktivni"
			 (click)="aktivovatMobilniMenu()"></div>

		<nav class="gov-header__nav"
			 [class.active]="jeMobilniMenuAktivni">
			<div class="gov-header__nav-logo"
				 [class.active]="jeMobilniMenuAktivni"></div>
			<div class="gov-header__nav-holder">
				<div class="gov-header__link"
					 routerLinkActive="is-active"
					 [routerLinkActiveOptions]="{exact: true}">
					<a class="gov-link gov-link--standalone gov-link--large gov-link--inversed"
					   routerLink="/">{{ 'hlavicka.uvod' | lokalizace }}</a>
				</div>

				<ng-container *ngIf="jeUzivatelPrihlasen">
					<div class="gov-header__link" routerLinkActive="is-active">
						<a class="gov-link gov-link--standalone gov-link--large gov-link--inversed"
						   routerLink="/zadosti">{{ 'hlavicka.zadosti' | lokalizace }}</a>
					</div>
					<div class="gov-header__link" routerLinkActive="is-active">
						<a class="gov-link gov-link--standalone gov-link--large gov-link--inversed"
						   routerLink="/ukoly">{{ 'hlavicka.ukoly' | lokalizace }}</a>
					</div>
					<div class="gov-header__link" routerLinkActive="is-active">
						<a class="gov-link gov-link--standalone gov-link--large gov-link--inversed"
						   routerLink="/registry">{{ 'hlavicka.registry' | lokalizace }}</a>
					</div>
					<div class="gov-header__link" routerLinkActive="is-active">
						<a class="gov-link gov-link--standalone gov-link--large gov-link--inversed"
						   routerLink="/systemove-zaznamy">{{ 'hlavicka.systemoveZaznamy' | lokalizace }}</a>
					</div>
					<div class="gov-header__link" routerLinkActive="is-active">
						<a class="gov-link gov-link--standalone gov-link--large gov-link--inversed"
						   routerLink="/kontextove-napovedy">{{ 'hlavicka.kontextoveNapovedy' | lokalizace }}</a>
					</div>
					<div class="gov-header__link" routerLinkActive="is-active">
						<a class="gov-link gov-link--standalone gov-link--large gov-link--inversed"
						   href="{{ urlVerejneAplikace + '/admin' }}">{{ 'hlavicka.podani' | lokalizace }}</a>
					</div>
				</ng-container>

				<div *ngIf="!jeUzivatelPrihlasen"
					 class="mobilniMenuUzivatelOdhlasen">
					<button class="gov-link--standalone gov-button gov-button--primary-outlined gov-button--inversed"
							[class.active]="jeMobilniMenuAktivni"
							(click)="prihlasit()">{{ 'hlavicka.prihlasitSe' | lokalizace }}</button>
				</div>

				<div *ngIf="jeUzivatelPrihlasen"
					 class="mobilniMenuUzivatelPrihlasen">
					<a class="jmeno-prihlaseneho gov-button gov-button--inversed"
					   [class.active]="jeMobilniMenuAktivni">{{ jmenoAPrijmeni }}</a>
					<button class="odhlasit gov-link--standalone gov-button gov-button--small gov-button--inversed"
							title="{{ 'hlavicka.odhlasitSe' | lokalizace }}"
							[class.active]="jeMobilniMenuAktivni"
							(click)="odhlasit()">{{ 'hlavicka.odhlasitSe' | lokalizace }}</button>
				</div>
			</div>
		</nav>
	</div>
</header>
