import {compare} from 'fast-json-patch';

export class NastrojeJsonu {

	public static parsovat(json: string): any {
		return JSON.parse(json);
	}

	public static prevestNaTextAParsovat(objekt: any): any {
		return JSON.parse(this.prevestNaText(objekt));
	}

	public static prevestNaText(objekt: any): any {
		return JSON.stringify(objekt);
	}

	public static vytvoritJsonPatch<T>(puvodniObjekt: T, novyObjekt: T): string {
		return JSON.stringify(compare(puvodniObjekt, novyObjekt));
	}

	public static pripravitJsonZakodovanyBase64JakoFormatovanyText(encodedJson: string): string {
		return JSON.stringify(JSON.parse(decodeURIComponent(escape(atob(encodedJson)))), null, 2);
	}

	public static odstranitPrazdneHodnotyZJsonTextu(text: string): string {
		return NastrojeJsonu.prevestNaText(NastrojeJsonu.odstranitPrazdneHodnotyZJsonDat(NastrojeJsonu.parsovat(text)));
	}

	public static odstranitPrazdneHodnotyZJsonDat(data: any): any {
		Object.keys(data).forEach((nazevHodnoty) => {
			if (data[nazevHodnoty] && typeof data[nazevHodnoty] === 'object') {
				const potomek = NastrojeJsonu.odstranitPrazdneHodnotyZJsonDat(data[nazevHodnoty]);
				if (potomek === undefined) {
					delete data[nazevHodnoty];
				}
			} else if (data[nazevHodnoty] === '' || data[nazevHodnoty] === null || data[nazevHodnoty] === undefined) {
				delete data[nazevHodnoty];
			}
		});

		return Object.keys(data).length > 0 || data instanceof Array ? data : undefined;
	}

	public static jeTextJson(text: string) {
		try {
			JSON.parse(text);
		} catch (e) {
			return false;
		}

		return true;
	}
}
