export enum ZdrojovaSluzba {
	'vvz-antivirova-kontrola' = 'vvz-antivirova-kontrola',
	'vvz-hlidani-lhut' = 'vvz-hlidani-lhut',
	'vvz-idm-keycloak' = 'vvz-idm-keycloak',
	'vvz-ien' = 'vvz-ien',
	'vvz-kontextova-napoveda' = 'vvz-kontextova-napoveda',
	'vvz-log' = 'vvz-log',
	'vvz-monitoring' = 'vvz-monitoring',
	'vvz-nia' = 'vvz-nia',
	'vvz-notifikace-dodavatelu' = 'vvz-notifikace-dodavatelu',
	'vvz-notifikace-zadavatele' = 'vvz-notifikace-zadavatele',
	'vvz-odesilani-mailu' = 'vvz-odesilani-mailu',
	'vvz-sprava-subjektu-a-uzivatelu' = 'vvz-sprava-subjektu-a-uzivatelu',
	'vvz-ted' = 'vvz-ted',
	'vvz-ukoly-administratora' = 'vvz-ukoly-administratora',
	'vvz-uloziste-formularu-bridge' = 'vvz-uloziste-formularu-bridge',
	'vvz-validace' = 'vvz-validace',
	'vvz-vypocet-lhut' = 'vvz-vypocet-lhut',
	'vvz-xml-editor' = 'vvz-xml-editor',
	'vvz-xml-formulare' = 'vvz-xml-formulare',
	'vvz-uloziste-formularu' = 'vvz-uloziste-formularu',
	'vvz-uloziste-formularu-export-app' = 'vvz-uloziste-formularu-export-app',
	'vvz2-ted' = 'vvz2-ted',
	'vvz2-xml-generovani' = 'vvz2-xml-generovani',
	'vvz2-json-formulare' = 'vvz2-json-formulare',
	'vvz2-validace-arbitr' = 'vvz2-validace-arbitr'
}
