import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatNativeDateModule, MatOptionModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule} from '@angular-material-components/datetime-picker';
import {QuillModule} from 'ngx-quill';
import {PipesModule} from '../pipes/pipes.module';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {FormularInputComponent} from './formularove-prvky/vstupy/formular-input/formular-input.component';
import {FormularSelectComponent} from './formularove-prvky/vstupy/formular-select/formular-select.component';
import {FormularKalendarComponent} from './formularove-prvky/vstupy/formular-kalendar/formular-kalendar.component';
import {FormularCheckboxComponent} from './formularove-prvky/vstupy/formular-checkbox/formular-checkbox.component';
import {FormularChipsComponent} from './formularove-prvky/vstupy/formular-chips/formular-chips.component';
import {FormularSouborComponent} from './formularove-prvky/vstupy/formular-soubory/formular-soubor.component';
import {FormularMultipleAutocompleteComponent} from './formularove-prvky/vstupy/formular-multiple-autocomplete/formular-multiple-autocomplete.component';
import {FormularMultipleSelectComponent} from './formularove-prvky/vstupy/formular-multiple-select/formular-multiple-select.component';
import {FormularPrepinacComponent} from './formularove-prvky/vstupy/formular-prepinac/formular-prepinac.component';
import {FormularSelectBooleanComponent} from './formularove-prvky/vstupy/formular-tabulka/formular-backend-tabulka/formular-select-boolean/formular-select-boolean.component';
import {FormularFrontendTabulkaSelectComponent} from './formularove-prvky/vstupy/formular-tabulka/formular-frontend-tabulka/form-frontend-table-select/formular-frontend-tabulka-select.component';
import {FormularTextovePoleComponent} from './formularove-prvky/vstupy/formular-textove-pole/formular-textove-pole.component';
import {FormularWysiwygEditorComponent} from './formularove-prvky/vstupy/formular-wysiwyg-editor/formular-wysiwyg-editor.component';
import {FormularHodinComponent} from './formularove-prvky/vstupy/formular-hodin/formular-hodin.component';
import {FormularHesloComponent} from './formularove-prvky/vstupy/formular-heslo/formular-heslo.component';
import {FormularKalendarACasComponent} from './formularove-prvky/vstupy/formular-kalendar-a-cas/formular-kalendar-a-cas.component';

@NgModule({
	declarations: [
		FormularInputComponent,
		FormularSelectComponent,
		FormularKalendarComponent,
		FormularCheckboxComponent,
		FormularChipsComponent,
		FormularSouborComponent,
		FormularMultipleAutocompleteComponent,
		FormularMultipleSelectComponent,
		FormularPrepinacComponent,
		FormularSelectBooleanComponent,
		FormularFrontendTabulkaSelectComponent,
		FormularTextovePoleComponent,
		FormularWysiwygEditorComponent,
		FormularHodinComponent,
		FormularHesloComponent,
		FormularKalendarACasComponent
	],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		PipesModule,
		MatFormFieldModule,
		MatDatepickerModule,
		MatInputModule,
		MatCheckboxModule,
		MatChipsModule,
		MatIconModule,
		MatButtonModule,
		MatAutocompleteModule,
		MatSelectModule,
		MatSnackBarModule,
		MatNativeDateModule,
		MatSlideToggleModule,
		MatTooltipModule,
		MatOptionModule,
		QuillModule,
		NgxMaterialTimepickerModule,
		NgxMatDatetimePickerModule,
		NgxMatTimepickerModule,
		NgxMatNativeDateModule
	],
	exports: [
		FormularInputComponent,
		FormularSelectComponent,
		FormularKalendarComponent,
		FormularCheckboxComponent,
		FormularChipsComponent,
		FormularSouborComponent,
		FormularMultipleAutocompleteComponent,
		FormularMultipleSelectComponent,
		FormularPrepinacComponent,
		FormularSelectBooleanComponent,
		FormularFrontendTabulkaSelectComponent,
		FormularTextovePoleComponent,
		FormularWysiwygEditorComponent,
		FormularHodinComponent,
		FormularHesloComponent,
		FormularKalendarACasComponent
	]
})
export class FormModule {
}
