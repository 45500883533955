import {AbstractControl, UntypedFormControl} from '@angular/forms';
import {ValidacniZprava} from '../form/validatory/validacni-zprava';
import {Lokalizace} from '../lokalizace';
import {NastrojeFormularu} from './nastroje-formularu';

export class NastrojeValidace {

	public static readonly VZOR_EMAIL = '^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$';
	public static readonly VZOR_MOBIL = '^\\+?\\d{6,19}$';
	public static readonly VZOR_TELEFON = '^(\\+\\d{1,3}\\s\\d+(\\-\\d+)*((\\s)?/(\\s)?(\\+\\d{1,3}\\s)?\\d+(\\-\\d+)*)*)$';

	private static readonly nameOfTheRequiredValueValidator = 'required';

	public static nastavitError(formControl: AbstractControl, errorKey: string): void {
		if (!formControl.hasError(errorKey)) {
			formControl.setErrors({[errorKey]: true});
		}
	}

	public static odstranitError(formControl: AbstractControl, errorKey: string): void {
		if (formControl.hasError(errorKey)) {
			NastrojeFormularu.aktualizovatPrvekPouzeSobeBezVyslaniUdalosti(formControl);
		}
	}

	public static pripravitErrorZpravu(formControl: AbstractControl): ValidacniZprava {
		const errorKey = Object.keys(formControl.errors)[0];
		const validationMessage = this.pripravitValidacniZpravu(formControl.getError(errorKey))[errorKey];

		return validationMessage !== undefined ? validationMessage : new ValidacniZprava('formsValidation.invalidFormat', errorKey);
	}

	public static pripravitTextErrorZpravy(formControl: AbstractControl, helpTextIncludedWithTheError?: boolean, helpText?: string): string {
		const validationMessage = this.pripravitErrorZpravu(formControl);
		const validationMessageText = Lokalizace.formatovatText(validationMessage.klic, ...validationMessage.hodnoty);

		return validationMessageText + (helpTextIncludedWithTheError ? ` (${Lokalizace.pripravitText(helpText)})` : '');
	}

	public static jeNastavenValidatorPozadujiciHodnotu(formControl: AbstractControl): boolean {
		return this.jePouzitValidator(formControl, this.nameOfTheRequiredValueValidator);
	}

	public static jePouzitValidator(formControl: AbstractControl, validatorName: string): boolean {
		const temporaryFormControl = new UntypedFormControl('', formControl.validator);

		return temporaryFormControl.validator && temporaryFormControl.validator(temporaryFormControl) && !!temporaryFormControl.validator(temporaryFormControl).hasOwnProperty(validatorName);
	}

	public static jsouPrvkyFormulareValidni(...controls: AbstractControl[]): boolean {
		return controls.map(control => control.valid).every(Boolean);
	}

	private static pripravitValidacniZpravu(data?: any): any {
		return {
			'required': new ValidacniZprava('formsValidation.required'),
			'email': new ValidacniZprava('formsValidation.email'),
			'minlength': new ValidacniZprava('formsValidation.minLength', data.requiredLength),
			'maxlength': new ValidacniZprava('formsValidation.maxLength', data.requiredLength),
			'pattern': new ValidacniZprava('formsValidation.invalidFormat'),
			'valueCombination': new ValidacniZprava('formsValidation.valueCombination'),
			'passwordCombination': new ValidacniZprava('formsValidation.passwordsDoNotMatch'),
			'rangeFirstValueGreaterThanSecond': new ValidacniZprava('formsValidation.rangeFirstValueGreaterThanSecond')
		};
	}
}
